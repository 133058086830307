<template>
  <div class="main">
    <el-divider></el-divider>
    <div class="content">
      <div class="xxinput">
        <div class="content-box">
          <el-form :rules="rules" ref="basicInfoRef" :model="formInfo" label-width="210px">
            <div class="content_residentinformation">
              <span class="blue"></span>
              <p>基本信息</p>
            </div>
            <div class="content-item">
              <el-form-item class="formitem" label="旅居国家：">
                <el-select filterable placeholder="请选择旅居国家：" style="width: 90%" v-model="formInfo.livingCountry">
                  <el-option v-for="item in nationalityselet" :key="item.label" :label="item.label"
                    @click.native="optionClick(item.value)" :value="item.label">
                    <span style="float: left">{{ item.label }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="formitem" label="风险等级：">
                <el-select style="width:90%" v-model="formInfo.riskLevel" placeholder="风险等级">
                  <el-option v-for="item in dangerselect" :key="item.value" :label="item.label" :value="item.label">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="入境城市：">
                <el-cascader clearable style="width:90%" :props="props"
                  v-model="formInfo.cityEntryCode" @change="handleCityEntry" ref="cityEntryRef" filterable>
                </el-cascader>
              </el-form-item>
              <el-form-item class="formitem" label="入境口岸：">
                <el-input style="width:90%;" v-model="formInfo.portEntry" placeholder="请输入入境口岸" />
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="来渝交通工具：">
                <el-select style="width:90%" class="top_left_inputnationality" v-model="formInfo.traffic"
                  placeholder="请选择">
                  <el-option v-for="item in carselect" :key="item.value" :label="item.label" :value="item.label">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="formitem" label="航班号/车次号/车牌号：">
                <el-input style="width:90%;" v-model="formInfo.trafficNo" placeholder="请输入" />
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="座位号：">
                <el-input style="width:90%" v-model="formInfo.seatNo" placeholder="请输入座位号" />
              </el-form-item>
              <el-form-item class="formitem" label="户籍地：">
                <el-cascader clearable style="width:90%" placeholder="可搜索地区"
                  v-model="formInfo.householdPlaceCode" :props="props" filterable @change="changeHouseholdPlace"
                  ref="householdPlaceRef"></el-cascader>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="目的地：">
                <el-cascader clearable style="width:90%" placeholder="可搜索地区" :props="props"
                  v-model="formInfo.destinationCode" filterable @change="handleDestination" ref="destinationRef"
                  @focus="isljdz03 = false"></el-cascader>
              </el-form-item>
              <el-form-item class="formitem" label="详细地址：">
                <el-input style="width:90%" v-model="formInfo.destinationDetail" placeholder="请输入详细地址" />
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="所属街道：">
                <el-input style="width:90%" v-model="formInfo.destinationStreet" placeholder="请输入所属街道" />
              </el-form-item>
              <el-form-item class="formitem" label="病例姓名：">
                <el-input style="width:90%" v-model="formInfo.caseName" placeholder="请输入病例姓名" />
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="接触时间：">
                <el-date-picker style="width:90%" type="datetime" v-model="formInfo.contactTime"
                  format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间"></el-date-picker>
              </el-form-item>
              <el-form-item class="formitem" label="抵渝时间：">
                <el-date-picker style="width:90%" type="datetime" v-model="formInfo.arrivalTime"
                  format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间"
                  @change="reachDate"></el-date-picker>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="备注：">
                <el-input style="width:90%" v-model="formInfo.remark" placeholder="请输入" />
              </el-form-item>
            </div>


            <div class="content_residentinformation">
              <span class="blue"></span>
              <p>入住筛查</p>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="是否常驻居民：" prop="residentFlag">
                <el-radio-group style="width:90%" v-model="formInfo.residentFlag">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="情绪是否稳定：" prop="emotionFlag">
                <el-radio-group style="width:90%" v-model="formInfo.emotionFlag">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item class="formitem" label="是否持24小时核酸阴性证明：" prop="covid24hProveFlag">
                <el-radio-group style="width:90%;" v-model="formInfo.covid24hProveFlag">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="是否持健康绿码：" prop="healthGreenCodeFlag">
                <el-radio-group style="width:90%" v-model="formInfo.healthGreenCodeFlag">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item class="formitem" label="入住体温：" prop="temperatureEntry">
                <el-input style="width:90%" placeholder="请输入体温℃" v-model="formInfo.temperatureEntry"></el-input>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item class="formitem" label="有无咳嗽、乏力等症状：" prop="coughFlag">
                <el-radio-group style="width:90%;" v-model="formInfo.coughFlag">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item class="formitem" label="是否已接种疫苗：" prop="vaccinationFlag">
                <el-radio-group style="width:90%;" v-model="formInfo.vaccinationFlag">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <div class="content-item">
              <el-form-item v-show="formInfo.coughFlag == '1'" class="formitem" label="症状描述：">
                <el-input style="width:90%" placeholder="请输入症状" v-model="formInfo.coughDetail"></el-input>
              </el-form-item>
              <el-form-item v-show="formInfo.vaccinationFlag == '1'" class="formitem" label="是否已完成接种：">
                <el-radio-group style="width:90%;" v-model="formInfo.vaccinationFinishFlag">
                  <el-radio label="1">是</el-radio>
                  <el-radio label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listCounty } from "../../api/QuarantineRegistration/quarantineRegistrationpop";
import { http, serverIP } from "../../api/index";
import constants from '../../common/constants';
let cities = localStorage.getItem("dqlist");

export default {
  data() {
    return {
      formInfo: {
        livingAreaCode: [],
        destinationCode: [],
        cityEntryCode: [],
        riskLevel: "高风险",
        lifetime: "", //时间
        arrivalTime: "", //抵重庆时间
        contactTime: "",
        caseName: "",
        destinationDetail: "",
        destinationStreet: "",
        residentFlag: "",
        coughDetail: "",
        vaccinationFinishFlag: ""
      }, //人员信息
      rules: {
        // emotionFlag: [{ required: true, message: "请选择情绪是否稳定", trigger: "change" }],
        // healthGreenCodeFlag: [{ required: true, message: "请选择是否持健康绿码", trigger: "change" }],
        // covid24hProveFlag: [{ required: true, message: "请选择是否持24小时核酸阴性证明", trigger: "change" }],
        // temperatureEntry: [{ required: true, message: "请输入体温℃", trigger: "blur" }],
        // coughFlag: [{ required: true, message: "请选择有无咳嗽、乏力等症状", trigger: "change" }],
        // vaccinationFlag: [{ required: true, message: "请选择是否接种疫苗", trigger: "change" }],
      },
      carselect: constants.VEHICLE_TYPE,  // 交通工具
      dangerselect: constants.DANGER_TYPE, //危险等级
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          // console.log('lazyLoad,', node)
          if (node.level == 0) {
            let cities = JSON.parse(localStorage.getItem("dqlist"));
            setTimeout(() => {
              resolve(cities);
            }, 0)
          } else {
            let formm = { label: node.data.value };
            http({
              method: "post",
              url: `/ohealth/api/v1/system/common/subList`,
              data: formm
            }).then(function (response) {
              let list = response.data.data;
              const nodes = list.map(item => ({
                value: item.value,
                label: item.label,
                leaf: item.leaf == 3
              }));

              resolve(nodes);
            })
          }
        }
      },
    };
  },

  props: [
    // 人群分类
    'crowdType',
    "redform"
],

  watch: {
    formInfo: {
      handler(val) {
        if (val.arrivalTime) {
          this.reachDate()
        }
      },
      deep: true
    }
  },

  created() {
    this.listCounty();
    // let { rowdata } = this.$route.query;
  },
  mounted(){
    // this.$forceUpdate()
    // this.formInfo = this.redform
    this.$nextTick(() => {
      // 因为和 newregistration 在同一页面，所以也可以拿到页面传参过来的值
      let { rowdata } = this.$route.query;
      console.log(rowdata,'打印输出rowdata在旅居地区和城市那里')
      // 如果不是新增btn点进来的回显（页面加载完之后，执行）
        if (rowdata !== "add") {
          // console.log(rowdata);
          this.formInfo = {
            ...rowdata,
            livingCountry: rowdata.livingCountry,
            livingAreaCode: rowdata.livingAreaCode ? rowdata.livingAreaCode.split(','): [],
            cityEntry: rowdata.cityEntry,
            cityEntryCode: rowdata.cityEntryCode ? rowdata.cityEntryCode.split(',') : [],
            destination:rowdata.destination,
            destinationCode: rowdata.destinationCode ? rowdata.destinationCode.split(','): [],
            householdPlace:rowdata.householdPlace,
            householdPlaceCode: rowdata.householdPlaceCode ? rowdata.householdPlaceCode.split(','): [],
          }
          delete this.formInfo.startTime
      } 
    })
  },

  methods: {
    // 提交
    comm() {
      this.$refs["basicInfoRef"].validate(valid => {
        if (valid) {

        } else {
          return false;
        }
      });
    },

    // 重置
    resetf() {
      this.$refs.basicInfoRef.resetFields()
    },

    // 国籍
    async listCounty() {
      let { data } = await listCounty();
      this.nationalityselet = data.data;
    },

    // 入境城市
    handleCityEntry(value) {
      let node = this.$refs.cityEntryRef.getCheckedNodes()[0];
      if (node) {
        let cityEntry = node.pathLabels.toString();
        this.formInfo.cityEntry = cityEntry;
        this.formInfo.cityEntryCode = value;
      }
    },

    // 户籍地
    changeHouseholdPlace(value) {
      let node = this.$refs.householdPlaceRef.getCheckedNodes()[0];
      if (node) {
        let householdPlace = node.pathLabels.toString();
        this.formInfo.householdPlace = householdPlace;
        this.formInfo.householdPlaceCode = value;
      }
    },

    // 	目的地
    handleDestination(value) {
      let node = this.$refs.destinationRef.getCheckedNodes()[0];
      if (node) {
        let destination = node.pathLabels.toString();
        this.formInfo.destination = destination;
        this.formInfo.destinationCode = value;
      }
    },

    optionClick(v) {
      this.formInfo.livingCountryCode = v;
    },

    // 抵渝时间
    reachDate() {
      this.$emit("changReachDate", this.formInfo.arrivalTime)
    }
  }
};
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;

  .content {
    .content_residentinformation {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    .content-box {
      margin-top: 40px;
      width: 100%;

      .content-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.formitem {
  width: 50%;
}

.xxinput {
  margin-bottom: 20px;
}

.bottom {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>
