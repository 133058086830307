<!-- 密切接触医学观察 By阿旭 -->
<template>
  <div class="main">
    <el-divider></el-divider>
    <div class="content">
      <div class="xxinput">
        <div class="content_residentinformation">
          <span class="blue"></span>
          <p>入住筛查</p>
        </div>

        <div class="content_residentinformationForm">
          <el-form :rules="rules" ref="hightemporaryform" :model="hightemporaryform" label-width="140px">
            <div class="content_residentinformationForm_div">
              <el-form-item class="formitem" :label="checkForm==4?'阳性姓名: ':'密接姓名: '" prop="closelyName">
                <el-input style="width:90%;" v-model="hightemporaryform.closelyName" />
              </el-form-item>
              <el-form-item class="formitem" :label="checkForm==4?'阳性接触时间：':'密接时间：'" prop="closelyTime">
                <el-date-picker style="width:90%" class="top_left_checktime" type="datetime"
                  v-model="hightemporaryform.closelyTime" format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择时间" @change="contiguityDate"></el-date-picker>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['hightemporaryformObj','crowdTypeId','checkForm'],
  data() {
    return {
      hightemporaryform: this.hightemporaryformObj,
      // hightemporaryform: {
      //   closelyName:"123",
      //   closelyTime: "" //时间
      // }, //人员信息
      rules: {
        closelyName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        closelyTime: [{ required: true, message: "请选择密切时间", trigger: "blur" }]
      },
    };
  },
  mounted(){
    // console.log(this.hightemporaryformObj);
  },
  watch: {
    hightemporaryform: {
      handler(val) {
        if (val.closelyTime) {
          this.contiguityDate()
        }
      },
      deep: true
    }
  },

  methods: {
    // 提交
    comm() {
      this.$refs.hightemporaryform.validate(valid => {
        if (!valid) {
          // this.$message.warning('姓名或时间未填写完整！')
          return false;
        }
      });
    },
    // 重置
    resetf() {
      this.$refs.hightemporaryform.resetFields();
    },

    // 密接时间
    contiguityDate() {
      this.$emit("changeContiguityDate", this.hightemporaryform.closelyTime)
    }
  }
};
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;

  .content {
    .content_residentinformation {
      display: flex;
      align-items: center;
    }

    .content_residentinformationForm {
      margin-top: 40px;
      width: 100%;

      .content_residentinformationForm_div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.blue {
  width: 4px;
  height: 20px;
  background-color: #3476f5;
  margin-right: 6px;
}

.formitem {
  width: 50%;
}

.xxinput {
  margin-bottom: 20px;
}
</style>
